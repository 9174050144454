import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div style={{ display: "grid" }}>
    {/* You can use a GatsbyImage component if the image is dynamic */}
    <StaticImage
      style={{
        gridArea: "1/1",
        // You can set a maximum height for the image, if you wish.
        height: 600,
      }}
      width={1920}
      layout="constrained"
      // This is a presentational image, so the alt should be an empty string
      alt=""
      // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash
      src="../images/sections/stats-1.jpg"
      formats={["auto", "webp", "avif"]}
    />
    <div
      style={{
        // By using the same grid area for both, they are stacked on top of each other
        gridArea: "1/1",
        position: "relative",
        // This centers the other elements inside the hero component
        placeItems: "center",
        display: "grid",
      }}
    >
      {/* Any content here will be centered in the component */}
      <div className='w-full max-w-screen-xl flex flex-col xl:flex-row items-center mx-auto'>
        <div className='w-11/12 flex flex-col items-center mx-auto'>
          <h1 className='text-5xl md:text-6xl font-extrabold text-white text-center'>
            <span className='leading-tight'>Page Not Found</span>
          </h1>
        </div>
      </div>
    </div>
  </div>
  </Layout>
)

export default NotFoundPage
